import type { DocumentData, DocumentReference } from 'firebase/firestore'
import { EmployeeModel } from './EmployeeModel'
import { RatingModel } from './RatingModel'
import { Earnings } from './EarningsModel'
import { PermissionsModel } from './PermissionsModel'

export class DeliverModel extends EmployeeModel {
  road: string[] | null
  rating: RatingModel | null
  dailyHistoryRoad: string[] | null
  earnings: Earnings | null
  visibilityAccess: DocumentReference[] | null
  permissions: PermissionsModel

  constructor(data: Partial<DeliverModel>, reference?: DocumentReference) {
    super(
      data,
      reference,
    )
    this.road = data.road || []
    this.earnings = data.earnings
      ? new Earnings(data.earnings)
      : null
    this.visibilityAccess = data.visibilityAccess || []
    this.rating = data.rating
      ? new RatingModel(data.rating)
      : null
    this.dailyHistoryRoad = data.dailyHistoryRoad || []
    this.permissions = data.permissions
      ? new PermissionsModel(data.permissions)
      : new PermissionsModel({})
  }

  toMap() {
    return {
      ...super.toMap(),
      road: this.road,
      earnings: this.earnings
        ? this.earnings.toMap()
        : null,
      visibilityAccess: this.visibilityAccess,
      rating: this.rating
        ? this.rating.toMap()
        : null,
      dailyHistoryRoad: this.dailyHistoryRoad,
      permissions: this.permissions.toMap(),
    }
  }
}

export function mapDelivers(deliver: DocumentData) {
  return new DeliverModel(
    deliver.data(),
    deliver.ref,
  )
}
