export const ROLE = {
  OWNER: 'owner',
  GET_DELIVER: 'getdeliver',
  DELIVER: 'deliver',
  MANAGER: 'manager',
  MODERATOR: 'moderator',
  CUSTOMER: 'customer',
  BILLER: 'biller',
  ACCOUNTANT: 'accountant',
}

export const COMPNAY_ROLES = [
  ROLE.DELIVER,
  ROLE.OWNER,
  ROLE.MANAGER,
  ROLE.MODERATOR,
  ROLE.CUSTOMER,
  ROLE.BILLER,
  ROLE.ACCOUNTANT,
]

export const isOwner = (displayName: string | null) => displayName === ROLE.OWNER
export const isGetDeliver = (displayName: string | null) => displayName === ROLE.GET_DELIVER
export const isDeliver = (displayName: string | null) => displayName === ROLE.DELIVER
export const isManager = (displayName: string | null) => displayName === ROLE.MANAGER
export const isModerator = (displayName: string | null) => displayName === ROLE.MODERATOR
export const isCustomer = (displayName: string | null) => displayName === ROLE.CUSTOMER
export const isBiller = (displayName: string | null) => displayName === ROLE.BILLER
export const isAccountant = (displayName: string | null) => displayName === ROLE.ACCOUNTANT

export function isManagerOrDeliverOrOwner(displayName: string) {
  return displayName === ROLE.MANAGER
    || displayName === ROLE.DELIVER
    || displayName === ROLE.OWNER
}

export const isManagerOrDeliver = (displayName: string) => displayName === ROLE.MANAGER || displayName === ROLE.DELIVER

export const isManagerOrGetDeliver = (displayName: string) => displayName === ROLE.MANAGER || displayName === ROLE.GET_DELIVER

export const isDeliverOrGetDeliver = (displayName: string) => displayName === ROLE.DELIVER || displayName === ROLE.GET_DELIVER

export const isDeliverOrOwner = (displayName: string | null) => displayName === ROLE.DELIVER || displayName === ROLE.OWNER

export const isManagerOrOwner = (displayName: string) => displayName === ROLE.MANAGER || displayName === ROLE.OWNER

export const isGetDeliverOrBiller = (displayName: string | null) => displayName === ROLE.GET_DELIVER || displayName === ROLE.BILLER

export const isGetDeliverOrManagerOrBillerOrAccountant = (displayName: string | null) => displayName === ROLE.GET_DELIVER || displayName === ROLE.MANAGER || displayName === ROLE.BILLER || displayName === ROLE.ACCOUNTANT
