export class PermissionsModel {
  canEditMenuReturns: boolean
  canEditRoute: boolean
  canEditEmployee: boolean
  canEditDeliverSells: boolean
  hasDeliversSellsAccess: boolean
  hasEmployeesAccess: boolean
  hasRoutesAccess: boolean
  hasMenuReturnsAccess: boolean
  hasMenuReturnsReportAccess: boolean
  canEditMenuReturnsReport: boolean

  constructor(data: Partial<PermissionsModel>) {
    this.canEditMenuReturns = data.canEditMenuReturns || false
    this.canEditRoute = data.canEditRoute || false
    this.canEditEmployee = data.canEditEmployee || false
    this.canEditDeliverSells = data.canEditDeliverSells || false
    this.hasDeliversSellsAccess = data.hasDeliversSellsAccess || false
    this.hasEmployeesAccess = data.hasEmployeesAccess || false
    this.hasRoutesAccess = data.hasRoutesAccess || false
    this.hasMenuReturnsAccess = data.hasMenuReturnsAccess || false
    this.hasMenuReturnsReportAccess = data.hasMenuReturnsReportAccess || false
    this.canEditMenuReturnsReport = data.canEditMenuReturnsReport || false
  }

  toMap() {
    return {
      canEditMenuReturns: this.canEditMenuReturns || false,
      canEditRoute: this.canEditRoute || false,
      canEditEmployee: this.canEditEmployee || false,
      canEditDeliverSells: this.canEditDeliverSells || false,
      hasDeliversSellsAccess: this.hasDeliversSellsAccess || false,
      hasEmployeesAccess: this.hasEmployeesAccess || false,
      hasRoutesAccess: this.hasRoutesAccess || false,
      hasMenuReturnsAccess: this.hasMenuReturnsAccess || false,
      hasMenuReturnsReportAccess: this.hasMenuReturnsReportAccess || false,
      canEditMenuReturnsReport: this.canEditMenuReturnsReport || false,
    }
  }
}
